import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./contactinfo.css";
import { useTranslation } from "react-i18next";

export default function ContactInfo() {
  const { t } = useTranslation();
  return (
    <div className="contact-info">
      <h6 className="footer-heading">
        {t("footer.contact-info")}
        <div className="footer-heading-underline"></div>
      </h6>
      <div className="contact-item">
        <MailOutlineIcon className="icon" />
        <a href="mailto:info@dhi2025.com" className="contact-text">
          info@dhi2025.com
        </a>
      </div>
      <div className="contact-item">
        <LocationOnIcon className="icon" />
        <p className="contact-text">Doof Vlaanderen, Dorpsstraat 43, 9052 {t("footer.location")}</p>
      </div>
    </div>
  );
}
